import type { OrderHistory } from "~/api_gen";
import { getGameDataFromOrder } from "~/components/Profile/OrderHistory/getGameData.ts";

export const repeatOrder = async (order: OrderHistory) => {
  if (await getGameDataFromOrder(order)) {
    const productsStore = useProductsStore();
    document.documentElement.style.overflow = "hidden";
    productsStore.showOrderConfirmForm = true;
  }
};

export const handleHide = () => {
  const productsStore = useProductsStore();
  productsStore.showOrderConfirmForm = false;
  productsStore.showUserDataForm = false;
  document.documentElement.style.overflow = "";
};
