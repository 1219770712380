<template>
  <div :class="$style['promo']">
    <div :class="$style['promo-descr']">
      <div :class="$style['promo-descr_subtitle']">
        {{ t("topup.promo") }}
      </div>
      <div :class="$style['promo-descr_title']">
        {{ promo.code }}
      </div>
    </div>
    <IconCopy
      :class="$style['promo-copy']"
      data-test="history-promo-copy-icon"
      @click="copy"
    />
  </div>
</template>
<script setup lang="ts">
import { Clipboard } from "@capacitor/clipboard";
import type { PUBGCode, VoucherCode } from "~/api_gen";

const { t } = useI18n();
const notificationStore = useNotificationsStore();

const props = defineProps<{
  promo: PUBGCode | VoucherCode;
}>();

const { promo } = toRefs(props);

const copy = async (): Promise<void> => {
  try {
    await Clipboard.write({
      string: promo.value.code?.toString(),
    });
    notificationStore.showNotification({
      text: t("notifications.copy"),
      type: "success",
    });
  } catch (e: any) {
    //
  }
};
</script>

<style module lang="scss">
.promo {
  $mobile-history: 640px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-lg) var(--spacing-2xl);
  border-radius: var(--radius-3xl);
  background: var(--general-transparent-dark-50);
  min-width: 212px;
  gap: 0.5rem;

  @media screen and (max-width: $mobile) {
    min-width: auto;
  }

  &-descr {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xs);
    justify-content: center;

    &_subtitle {
      color: var(--general-transparent-light-35);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.06px;
    }

    &_title {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.09px;

      @media screen and (max-width: $mobile-history) {
        max-width: 15ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media screen and (max-width: $mobile) {
        max-width: 10ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-copy {
    width: 20px;
    height: 20px;
    fill: white;
    fill-opacity: 0.35;
    cursor: pointer;
  }
}
</style>
